<template>
  <div class="no-padding">

    <div v-if="is_multi && !is_dropdown">

      <div class="check-list">
        <div v-for="(prop, j) in properties" v-bind:key="j">

          <AttributeGroupQuestionAnswerWithText
            :index="index"
            class="mt-4"
            :prop="prop"
            @answer_selected="answer_selected"
            @answer_removed="answer_removed"
          />

        </div>
      </div>
    </div>


    <div v-if="is_dropdown">
      <b-form-group id="properties-group" label-for="properties">
        <b-form-select
          id="properties"
          v-model="selected"
          :options="property_options"
        />

      </b-form-group>
    </div>

    
    <div v-if="!is_multi && !is_dropdown">
      <div class="form-group">
        <div class="radio-list">
          <div v-for="(prop, j) in properties" v-bind:key="j">
            <label class="radio radio-outline radio-success mb-4">
              <input type="radio" v-model="selected" :value="prop.prop_id" name="answer_radio" />
              <span></span>
              {{ prop.name }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selected && is_free_text">
      <b-form-input
        v-model="free_text"
        @change="free_text_changed"
        :state="validate_text"
        >
      </b-form-input>
    </div>


  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';

import AttributeGroupQuestionAnswerWithText from '@/view/components/attribute_forms/AttributeGroupQuestionAnswerWithText.vue';


export default {

  name: 'AttributeGroupQuestionAnswers',
  props: ['index', 'is_multi', 'is_dropdown', 'is_mandatory', 'properties'],
  /**

   property_group: {
     group_id,
     company_id,
     type,
     name,
     descr,
     is_registration,
     deleted,
     is_mandatory,
     is_multi,
     is_dropdown,
     properties: [
      {
        prop_id           Int       @id @default(autoincrement())
        name              String?   @db.VarChar(256)
        descr             String?   @db.LongText
        deleted           Boolean   @default(false) @db.TinyInt
        group_id          Int       @db.Int
        is_free_text      Boolean   @default(false) @db.TinyInt /// allows for any value
      }
     ]
   }

  */
  emits: ['selected'],
  mixins: [ toasts ],

  components: {
    AttributeGroupQuestionAnswerWithText
  },

  watch: {

    index: {
      deep: true,
      handler(val) {

        // if there is only 1 property to select, select it and emit
        if (this.properties && this.properties.length === 1) {
          console.log('only 1 prop');
          this.selected = this.properties[0].prop_id;
          this.selected_attributes = [ { prop_id: this.selected, text: this.free_text } ];
          this.emit_selection();

          return;
        }

        this.selected = null;
        this.selected_attributes = [];
        this.free_text = null;

      }
    },

    properties: {
      deep: true,
      handler(val) {
        this.selected = null;

        if (!this.properties) {
          return;
        }

        if (!this.is_multi && this.properties.length === 1) {
          this.selected = this.properties[0].prop_id;
          this.selected_attributes = [ { prop_id: this.properties[0].prop_id, text: '' } ];
        }
      }
    },


    selected: {
      deep: true,
      handler(val) {
        // should only be for single selections
        if (!this.is_multi) {
          this.selected_attributes = [ { prop_id: val, text: this.free_text } ];

          this.emit_selection()
        }
      }
    },

  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    property_options() {
      if (!this.properties) {
        return [];
      }

      return this.properties.map((item) => ({ text: item.name, value: item.prop_id }));
    },
    is_free_text() {
      const prop = this.properties.find((item) => item.prop_id === this.selected);

      return prop ? prop.is_free_text : false;
    },
    validate_text() {
      return this.free_text && this.free_text.length < 32 ? true : null;
    }
  },

  mounted() {
    if (this.group_ids) {
      this.local_group_ids = [...this.group_ids];

      this.selected_attributes = [];

      if (!this.is_multi && this.properties.length === 1) {
        this.selected = this.properties[0].prop_id;
        this.selected_attributes = [ { prop_id: this.properties[0].prop_id, text: '' } ];
      }
    }

    if (this.selected) {
      this.selected_attributes = [ { prop_id: this.selected, text: this.free_text } ];
      this.emit_selection();
    }
  },

  methods: {

    answer_removed(prop) {
      this.selected_attributes = this.selected_attributes.filter((item) => item.prop_id !== prop.prop_id);

      this.emit_selection();
    },
    /*
    prop = { prop_id: number, text: string | null }
    */
    answer_selected(prop) {
      

      if (!this.is_multi) {

        const found = this.selected_attributes.find((item) => item.prop_id === prop.prop_id);

        if (found) {
          this.selected_attributes = this.selected_attributes.filter((item) => item.prop_id !== prop.prop_id);
        }
        else {
          this.selected_attributes.push(prop);
        }

      }
      else {

        const index = this.selected_attributes.findIndex((item) => item.prop_id === prop.prop_id);

        if (index > -1) {
          this.selected_attributes[index] = prop;
        }
        else {
          this.selected_attributes.push(prop);
        }

        this.selected_attributes = [...this.selected_attributes];
      }

      this.emit_selection();
    },

    emit_selection() {

      console.log('AttributeGroupQuestionAnswers, emit_selection', this.selected_attributes);
      
      this.$emit('selected', this.selected_attributes);
    },

    free_text_changed() {

      if (this.selected_attributes.length === 0) {
        return;
      }

      this.selected_attributes[0].text = this.free_text;

      this.emit_selection();
    },

  },

  data() {
    return {
      selected_attributes: [],
      free_text: null,
      selected: null
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
