<template>
  <div class="no-padding">
    
    <div class="d-flex align-items-center mb-4">
      <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
        <input
          name="answer_checkbox"
          type="checkbox"
          v-model="selected"
          :value="true"
        />
        <span></span>
      </label>
      <span class="ml-3 cursor-pointer">{{ prop.name }}</span>
    </div>

    <div v-if="selected && prop.is_free_text">
      <b-form-input
        v-model="free_text"
        @change="answer_changed"
        :state="validate_text"
        >
      </b-form-input>
    </div>


  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';

export default {

  name: 'AttributeGroupQuestionAnswerWithText',
  props: ['prop','index'],
  /**

   property_group: {
     group_id,
     company_id,
     type,
     name,
     descr,
     is_registration,
     deleted,
     is_mandatory,
     is_multi,
     is_dropdown,
     properties: [
      {
        prop_id           Int       @id @default(autoincrement())
        name              String?   @db.VarChar(256)
        descr             String?   @db.LongText
        deleted           Boolean   @default(false) @db.TinyInt
        group_id          Int       @db.Int
        is_free_text      Boolean   @default(false) @db.TinyInt /// allows for any value
      }
     ]
   }

  */
  emits: ['answer_selected', 'answer_removed'],
  mixins: [ toasts ],

  components: {

  },

  watch: {
    index() {
      this.free_text = null;
      this.selected = false;
    },

    selected() {
      
      if (!this.selected) {
        this.free_text = null;
      }

      this.answer_changed();
    }
  },

  computed: {
    ...mapGetters(['companies', 'settings']),
    validate_text() {
      return this.free_text && this.free_text.length < 32 ? true : null;
    }
  },

  mounted() {
    if (this.selected) {
      if (this.prop.is_free_text && this.validate_text) {
        this.$emit('answer_selected', { prop_id: this.prop.prop_id, text: this.free_text });
      }
      else {
        this.$emit('answer_selected', { prop_id: this.prop.prop_id, text: null });
      }
    }
  },

  methods: {
    answer_changed() {
      if (this.selected) {
        if (this.prop.is_free_text && this.validate_text) {
          this.$emit('answer_selected', { prop_id: this.prop.prop_id, text: this.free_text });
        }
        else {
          this.$emit('answer_selected', { prop_id: this.prop.prop_id, text: null });
        }
      }
      else {
        this.$emit('answer_removed', { prop_id: this.prop.prop_id, text: this.free_text });
      }

    }

  },

  data() {
    return {
      selected: false, // if selected

      free_text: null
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
