<template>
  <div class="no-padding">

    <h5 class="mb-4">{{ local_property_group.descr }}</h5>

    <AttributeGroupQuestionAnswers
      :index="index"
      :properties="local_property_group.properties"
      :is_multi="local_property_group.is_multi"
      :is_dropdown="local_property_group.is_dropdown"
      :is_mandatory="local_property_group.is_mandatory"

      @selected="answer_selected"
    />

  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';

import AttributeGroupQuestionAnswers from '@/view/components/attribute_forms/AttributeGroupQuestionAnswers.vue';

export default {

  name: 'AttributeGroupQuestion',
  props: ['index', 'property_group'],
  emits: ['selected'],
  /**

   property_group: {
     group_id,
     company_id,
     type,
     name,
     descr,
     is_registration,
     deleted,
     is_mandatory,
     is_multi,
     is_dropdown,
     properties: [
      {
        prop_id           Int       @id @default(autoincrement())
        name              String?   @db.VarChar(256)
        descr             String?   @db.LongText
        deleted           Boolean   @default(false) @db.TinyInt
        group_id          Int       @db.Int
        is_free_text      Boolean   @default(false) @db.TinyInt /// allows for any value
      }
     ]
   }

  */

  mixins: [ toasts ],

  components: {
    AttributeGroupQuestionAnswers
  },

  watch: {
    property_group: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_property_group = { ...this.property_group };
      }
    },
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
  },

  mounted() {
    
    this.local_property_group = { ...this.property_group };
  },

  methods: {
    answer_selected(selection) {

      const selected_group = {
        group_id: this.local_property_group.group_id,
        selected_attributes: selection
      };

      this.$emit('selected', selected_group);
    }
  },

  data() {
    return {
      local_property_group: {}
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
